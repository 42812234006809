import React, { useState } from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { GP, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import {
  ensureListing,
  ensureUser,
  mapFieldsOptionsToLabel,
  truncateMessage,
} from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { AspectRatioWrapper, Button, H3, H5, H6, Modal, NamedLink, ResponsiveImage } from '..';
import { IoStar } from 'react-icons/io5';
import css from './ListingDoctorCard.module.css';
import { IoIosStarHalf } from 'react-icons/io';
import appSettings from '../../config/settings';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <span>
      {formattedPrice}
      {isBookable ? (
        <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
      ) : null}
    </span>
  );
};

export const ListingDoctorCardComponent = props => {
  const config = useConfiguration();
  const currencyConfig = appSettings.getCurrencyFormatting(config.currency);
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    listingFields,
    listingResponse,
    onManageDisableScrolling
  } = props;

  const isPublishedListing = listingResponse.length > 0 && listingResponse[0].attributes.state === "published";
  const [isProfileModal, setIsProfileModal] = useState(false);

  const specialityEnumOptions = listingFields?.find(lc => lc.key === 'speciality')?.enumOptions;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, description, publicData } = currentListing.attributes;
  const {
    education,
    category,
    work_experience,
    location,
    profileImage,
    rating = 0,
    reviews = 0,
    sub_category,
    serves_for,
    speciality,
  } = publicData || {};

  const avgRating = rating;
  const totalRatings = reviews;
  const starArray = [1, 2, 3, 4, 5];
  // Rating End
  const slug = createSlug(title);
  const authorName = title;
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;

  const setActivePropsMaybe = setActiveListing
    ? {
      onMouseEnter: () => setActiveListing(currentListing.id),
      onMouseLeave: () => setActiveListing(null),
    }
    : null;

  const showStarsByRatings = (ratingPeruser, totalRatings, starArray) => {
    const filledStars = Math.floor(ratingPeruser);
    const hasHalfStar = ratingPeruser % 1 !== 0;

    return (
      <div className={css.ratingContent}>
        {starArray.map(star => {
          return star === filledStars + 1 && hasHalfStar ? (
            <IoIosStarHalf className={css.starIconGold} key={star} />
          ) : (
            <IoStar
              className={star <= filledStars ? css.starIconGold : css.starIconGrey}
              key={star}
            />
          );
        })}
        <H6 className={css.ratingText}>
          {ratingPeruser.toFixed(1)} {`(${totalRatings} Review${totalRatings !== 1 ? 's' : ''})`}
        </H6>
      </div>
    );
  };

  const author = ensureUser(listing.author);
  const firstImage = author && author?.id && author?.profileImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const doctorCardExperience = intl.formatMessage({ id: 'DoctorCard.experience' });
  const doctorCardFee = intl.formatMessage({ id: 'DoctorCard.fee' });

  const educationMaybe = !!education ? (
    <>
      <p className={css.label}>{"Education"}</p>
      <div className={css.infoData}>{truncateMessage(education, 6)}</div>
    </>
  ) : null;

  const specialitiesMaybe = !!speciality ? (
    <div className={css.specialityContainer}>{/* {speciality} */}</div>
  ) : null;
  console.log(category, '&&& &&& => category');
  
  const categoryMaybe =
    !!category ? <div className={css.category}>{category == "g_p" ? "General Practitioner" : "Hospital Doctor"}</div> : null;

    
  const experienceMaybe = !!work_experience ? (
    <>
      <p className={css.label}>{doctorCardExperience}</p>
      <p className={css.infoData}>{truncateMessage(work_experience, 5)}</p>
    </>
  ) : null;
  const feeMaybe = !!price ? (
    <>
      <p className={css.label}>{doctorCardFee}</p>
      <div className={css.infoData}>
        <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
      </div>
    </>
  ) : null;
  const servesForMaybe = !!serves_for ? (
    <>
      <p className={css.label}>Serve for</p>
      <div className={css.infoData}>{serves_for}</div>
    </>
  ) : null;

  const timingTitle = intl.formatMessage({ id: 'JobCard.timingTitle' });
  const jobPostedAt = intl.formatMessage(
    { id: 'JobCard.jobPosted' },
    { postedAt: 'Posted: 2hr ago' }
  );
  const feeTitle = intl.formatMessage({ id: 'JobCard.feeTitle' });
  const proposalBtnText = intl.formatMessage({ id: 'JobCard.proposalBtnText' });

  return (
    <div className={classes}>
    
      <div className={css.mainInfoWrapper}>
        <div className={css.cardHeader}>
          <div className={css.profileInfo}>
            <AspectRatioWrapper
              className={css.aspectRatioWrapper}
              width={aspectWidth}
              height={aspectHeight}
              {...setActivePropsMaybe}
            >
              <ResponsiveImage
                rootClassName={css.rootForImage}
                alt={title}
                image={firstImage}
                variants={variants}
                sizes={renderSizes}
              />
            </AspectRatioWrapper>
            <div>
              <div className={css.authorInfo}>
              {title}
              </div>
              <H5 className={css.country}>{location ? location.address : 'United Kindom'}</H5>
            </div>
          </div>
        </div>
        <div>
          <H5 className={css.description}>{description}</H5>
        </div>
        
        <div className={css.cardBottom}>
        <div className={css.buttonGroups}>
            {!isPublishedListing ? (
              <div
              className={css.proposerBtn}
                type="button"
                onClick={() => setIsProfileModal(true)}
                // className={css.createNewListingLink}
              >
                {proposalBtnText}
              </div>
            ) : (
              <NamedLink name="ListingPage" params={{ id, slug }}>
                <div className={css.proposerBtn}>{proposalBtnText}</div>
              </NamedLink>
            )}
          </div>
          {/* <div className={css.postedDate}>Posted: 2hr ago</div> */}
        </div>
      </div>
      <div className={css.info}>
        <div className={css.mainInfo}>
          {/* {educationMaybe} */}
          {specialitiesMaybe}
          {categoryMaybe}
          <div className={css.ratingSection}>
            {showStarsByRatings(avgRating, totalRatings, starArray)}
          </div>
        </div>
        <div className={css.timingInfo}>
          {/* {experienceMaybe} */}
          {servesForMaybe}
          {feeMaybe}
        </div>
      </div>
      <Modal
        className={css.applyJobModal}
        id="ProfileRouteModal"
        isOpen={isProfileModal}
        onClose={() => setIsProfileModal(false)}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.termsWrapper}>
          <h4>You need to create job before book any doctor</h4>
          <NamedLink
            className={css.applyButton}
            name="EditListingPage"
            params={{
              slug,
              id,
              type: 'new',
              tab: 'details',
            }}
          >
            Create job 
          </NamedLink>
        </div>
      </Modal>
    </div>
  );
};

ListingDoctorCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingDoctorCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,
  // Responsive image sizes hint
  renderSizes: string,
  setActiveListing: func,
};

export default injectIntl(ListingDoctorCardComponent);
